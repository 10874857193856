import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import  "./index.css";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);


// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login"));
const Registration = React.lazy(() => import("./views/pages/registration"));
const ForgotPassword = React.lazy(() => import("./views/pages/forgotPassword"));
const OtpRegistration = React.lazy(() => import("./views/pages/otpRegistration"));
const OtpForgotPass = React.lazy(() => import("./views/pages/otpForgotPass"));
const SetNewPassword = React.lazy(() => import("./views/pages/setNewPassword"));
const CreateProfile = React.lazy(() => import("./views/pages/createProfile"));
const StripeAccountSuccess = React.lazy(() => import("./views/pages/stripeSuccess"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const App = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route exact path="/login" name="Login Page" element={<Login />} />
        <Route exact path="/registration" name="Registeration Page" element={<Registration />} />
        <Route exact path="/forgot-password" name="Password Reset Page" element={<ForgotPassword />} />
        <Route exact path="/verify-registration" name="Verify Registration" element={<OtpRegistration />} />
        <Route exact path="/verify-forgot-pass" name="Verify Forgot Pass" element={<OtpForgotPass />} />
        <Route exact path="/create-profile" name="Create Profile" element={<CreateProfile />} />
        <Route exact path="/set-new-password" name="Set New Password" element={<SetNewPassword />} />
        <Route exact path="/stripe-account-success" name="Stripe Account Success" element={<StripeAccountSuccess />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Home" element={<DefaultLayout />} />
      </Routes>
    </Suspense>
  );
};

export default App;
